<template>
  <div>
    <table>
      <tr>
        <th v-for="(item, index) in headerData" :key="index">{{ item }}</th>
      </tr>
      <tbody>
        <transition-group name="drag">
          <tr
            v-for="(item, index) in centerData"
            :key="index"
            :class="activeId == item.groupId ? 'active_tr' : 'hover_tr'"
            @click="selectGroup(item)"
            draggable="true"
            @dragenter="dragenter($event, index)"
            @dragover="dragover($event, index)"
            @dragstart="dragstart(index)"
          >
            <td>{{ item.groupName }}</td>
            <td>{{ item.isCommon === 1 ? "公共分组" : "私有分组" }}</td>
            <td class="action">
              <a v-show="item.userType == 1"
                ><a-icon type="form" @click="showChangeName(item)"
              /></a>
              <a v-show="item.userType == 1" @click="deleteGroup(item)"
                ><a-icon type="delete"
              /></a>
              <a-button
                v-show="item.userType != 1"
                @click="cancelAttention(item)"
                type="link"
                icon="close"
                :loading="cancelLoading && actionId == item.groupId"
              ></a-button>
              <!-- <a><a-icon type="vertical-align-middle" /></a> -->
            </td>
          </tr>
        </transition-group>
      </tbody>
    </table>
    <a-modal
      v-model="visibleName"
      title="分组名称"
      :footer="null"
      :maskClosable="false"
      :width="430"
    >
      <a-input v-model="groupName" style="width: 280px; margin-right: 12px" />
      <a-button type="primary" @click="saveRename" :loading="btnLoading"
        >确定</a-button
      >
    </a-modal>
  </div>
</template>
<script>
import _http from "@/api/personalcenter";
import debounce from "lodash/debounce";
export default {
  props: {
    headerData: {
      type: Array,
      default: [],
    },
    centerData: {
      type: Array,
      default: [],
    },
    type: {
      type: Number,
    },
  },
  data() {
    this.fetchActive = debounce(this.fetchActive, 500);
    return {
      visibleName: false,
      groupName: "",
      activeId: "",
      renameData: {},
      btnLoading: false,
      dragIndex: "",
      actionId: "",
      cancelLoading: false,
    };
  },
  methods: {
    disposeUserType(key) {
      let str = "";
      switch (key) {
        case 1:
          str = "分组创建人";
          break;
        case 2:
          str = "分组负责人";
          break;
        case 3:
          str = "分组协作人";
          break;
        case 4:
          str = "分组关注人";
          break;
        default:
          break;
      }
      return str;
    },
    showChangeName(value) {
      this.visibleName = true;
      this.renameData = value;
    },
    selectGroup(value) {
      console.log(value,'999999')
      const { groupId, isCommon } = value;
      if (this.activeId == groupId) return;
      this.activeId = groupId;
      this.$emit("getDealerList", { groupId, isCommon });
      this.$emit("getGroupDetail", groupId);
    },
    /*
     * @type 1：经销商   2：我的播主
     * */
    saveRename() {
      const { isCommon, groupId } = this.renameData;
      let payload = {
        groupId,
        name: this.groupName,
      };
      let httpApi = "";
      if (isCommon == 0 && this.type == 1) {
        httpApi = "changePrivatelyRename";
      } else if (isCommon == 0 && this.type == 2) {
        httpApi = "changeAuthorPrivateRename";
      } else {
        httpApi = "changeGroupRename";
        payload = {
          groupId,
          isCommon,
          groupName: this.groupName,
          groupType: this.type,
        };
      }
      this.btnLoading = true;
      _http[httpApi](payload)
        .then((res) => {
          if (res.code == 200) {
            this.$emit("changeList");
            this.visibleName = false;
          } else {
            this.$message.error(res.errmsg);
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    dragstart(index) {
      this.dragIndex = index;
    },
    dragenter(e, index) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (this.dragIndex !== index) {
        const moving = this.centerData[this.dragIndex];
        this.centerData.splice(this.dragIndex, 1);
        this.centerData.splice(index, 0, moving);
        // 排序变化后目标对象的索引变成源对象的索引
        this.dragIndex = index;
        // let arr = this.centerData.map((item) => {
        //   return { groupId: item.groupId };
        // });
        this.fetchActive();
      }
    },
    fetchActive() {
      let arr = this.centerData.map((item) => {
        return { groupId: item.groupId };
      });
      let groupIds = arr;
      let _http_api = ["saveDealerSort", "saveAuthorSort"];
      _http[_http_api[this.type - 1]](groupIds)
        .then((res) => {
          if (res.code !== 200) {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.$emit("changeList");
        });
    },
    dragover(e, index) {
      e.preventDefault();
    },
    // 取消分组关注接口
    cancelAttention(value) {
      let that = this;
      this.$confirm({
        title: "注意！",
        content: "确定不再关注该分组吗？",
        okText: "不再关注",
        onOk() {
          const { groupId, groupName, isCommon } = value;
          let payload = {
            groupId,
            groupName,
            isCommon,
            groupType: that.type,
          };
          that.actionId = groupId;
          that.cancelLoading = true;
          _http
            .cancelAttention(payload)
            .then((res) => {
              if (res.code == 200) {
                that.$emit("changeList");
              }
            })
            .finally(() => {
              that.cancelLoading = false;
            });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    // 删除分组
    deleteGroup(value) {
      const { groupId, isCommon } = value;
      let that = this;
      this.$confirm({
        title: "注意！",
        content: isCommon
          ? "该分组为公共分组，请谨慎删除！"
          : "该分组为私有分组，请谨慎删除！",
        okText: "确认删除",
        onOk() {
          let payload = {
            groupId,
            groupType: that.type,
          };
          let apiStr = "";
          if (isCommon) {
            if (that.type == 1) {
              apiStr = "deleteCommonGroup";
            } else {
              apiStr = "delAuthorCommonGroup";
            }
          } else {
            // apiStr = 'deleteGroup'
            if (that.type == 1) {
              apiStr = "deleteGroup";
            } else {
              apiStr = "delAuthorGroup";
            }
          }
          _http[apiStr](payload).then((res) => {
            if (res.code == 200 || res.code == 0) {
              that.$message.success("成功删除分组");
              that.$emit("changeList", 1);
            } else {
              that.$message.error(res.message);
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
table thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /*重要  表格固定算法*/
}

table {
  width: 100%;
  text-align: center;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  .hover_tr {
    &:hover {
      background: #e6f7ff;
    }
  }
  .active_tr {
    background: #e6f7ff;
  }
  tbody {
    display: block;
    max-height: calc(50vh - 168px);
    overflow-y: scroll;
  }
  tr {
    display: flex;
    .action {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      a {
        margin-right: 4px;
      }
    }
    th {
      height: 54px;
      background: #fafafa;
      line-height: 54px;
      color: rgba(0, 0, 0, 0.85);
      border-right: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
    }
    td {
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      color: rgba(0, 0, 0, 0.65);
      padding: 16px 0;
    }

    :nth-child(1) {
      flex: 8;
    }
    :nth-child(2) {
      flex: 7;
    }
    :nth-child(3) {
      flex: 9;
    }
  }
}
</style>