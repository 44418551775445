export default {
  column_group: [
    {
      title: '经销商分组',
      dataIndex: 'groupName',
      align: 'center',
    },
    {
      title: '分类',
      dataIndex: 'userType',
      align: 'center',
      scopedSlots: {
        customRender: 'userType',
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
    },
  ],

  column_dealer: [
    {
      title: '经销商名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '更多信息',
      align: 'center',
      scopedSlots: {
        customRender: 'more',
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: {
        customRender: 'action',
      },
      fixed: 'right',
    },
  ],
};
