<template>
  <div class="detail__box">
    <div class="mrb-8 font__b">
      <span><span v-if="groupType == 1">经销商</span><span v-else>播主</span>{{ detailData.isCommon == 1 ? '公共分组' : '私有分组' }}：</span
      ><span
        >{{ detailData.groupName }}
        <a-tooltip placement="top" trigger="click">
          <template slot="title">
            <div>编号：{{ detailData.groupCode }}</div>
            <div>ID：{{ detailData.groupId }}</div>
          </template>
          <a-icon type="info-circle" class="icon__s" />
        </a-tooltip>
      </span>
    </div>
    <div>
      <span>更新时间：</span><span>{{ detailData.mtime }}</span>
    </div>
    <div>
      <span>关注时间：</span><span>{{ detailData.mtime }}</span>
    </div>
    <div>
      <span
        >含{{ detailData.containPrincipal }}主体，{{ detailData.containDealer }}经销商<span v-if="groupType == 0"
          >,{{ detailData.containAuthor }}媒体号</span
        ></span
      >
    </div>
    <div>
      <span v-if="groupType == 1"
        >同步播主分组：<span>{{ detailData.syncCount }}</span></span
      >
      <span v-else>
        同步经销商分组：<span>{{ detailData.syncDealer }}</span></span
      >
    </div>
    <div v-if="groupType == 1">
      <span>负责人：</span><span>{{ detailData.personInCharge }}</span> <span class="mrg-create">创建人：</span
      ><span>{{ detailData.creator }}</span>
    </div>
    <div v-else>创建人：{{ detailData.creator }}</div>
    <div v-show="groupType == 1">
      <span>协作人：</span
      ><span v-for="(item, index) in detailData.collaborator" :key="index"
        >{{ item }}<span v-show="index < detailData.collaborator.length - 1">,</span></span
      >
    </div>

    <div class="flex_end">
      <a-popover  trigger="click" >
        <template slot="content" >
          <a-upload
            :action="uploadUrl"
            size="small"
            class="mrg-right"
            :headers="{
              Authorization: `Bearer ${auth.getToken()}`,
            }"
            :showUploadList="false"
            accept=".xlsx"
            @change="handleChange"
          >
            <a v-if="groupType == 1">上传经销商数据</a>
            <a v-else>上传播主数据</a>
          </a-upload>
          <br>
          <br>
          <a @click="handleImport">下载导入模板</a>
        </template>
        <a-button type="primary" size="small" class="mrg-right" v-show="detailData.isCreator == 1 || detailData.isLeader==1|| detailData.my == 1"> 导入 </a-button>
      </a-popover>
      <a-button type="primary" size="small" class="mrg-right" v-show="groupType == 1" @click="deriveGroup"
        >派生播主分组</a-button
      >
      <a-button type="primary" size="small" @click="changeGroup" v-show="detailData.isCommon == 1">公转私</a-button>
    </div>
  </div>
</template>
<script>
import _http from '@/api/personalcenter';
import { auth } from '@/utils';
export default {
  props: {
    detailData: {
      type: Object,
      default: {},
    },
    groupType: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    detailData: {
      handler(val) {
        let str = this.groupType == 1 ? '/bfz-dy/my-dealer/dealer-import/' : '/bfz-dy/my-author/author-import/';
        this.uploadUrl = process.env.VUE_APP_API_HOST + str + val.groupId;
        // this.uploadUrl = "http://localhost:10001/api" + str + val.groupId;
      },
    },
  },
  data() {
    return {
      auth,
      uploadUrl: '',
    };
  },
  methods: {
    handleImport() {
      if(this.groupType == 1){
        window.open("https://afanti-bfz-dev.oss-cn-hangzhou.aliyuncs.com/import_module/%E7%BB%8F%E9%94%80%E5%95%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx")
      }else{
        window.open("https://afanti-bfz-dev.oss-cn-hangzhou.aliyuncs.com/import_module/%E5%AA%92%E4%BD%93%E5%8F%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx")
      }
    },
    changeGroup() {
      // const { groupName, isCommon, groupId } = this.detailData;
      // let payload = {
      //   sourceGroupId:groupId,
      //   groupName,
      //   isSync:isCommon,
      // };
      // this.changeLoading = true
      // _http.changeGroup(payload).then((res) => {
      //   console.log(res)
      //   if(res.code != 200){
      //       this.$message.error(res.message)
      //   }
      // }).finally(()=>{
      //   this.changeLoading = false
      // });
      this.groupType == 1 ? this.$emit('handleChangeDealerGroup') : this.$emit('handleChangeAuthorGroup');
    },
    deriveGroup() {
      this.$emit('handleDeriveAuthorGroup');
    },
    handleChange(info) {
      console.log('info', info);
      const { response } = info.fileList[0];
      if (response.code) {
        this.$emit('updataList');
        this.$message.success('导入成功！');
      } else {
        this.$message.error('导入失败！');
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.detail__box {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 8px 12px;
}
.mrb-8 {
  margin-bottom: 4px;
}
.font__b {
  font-weight: bold;
}
.flex_end {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  // .popover-content{
  //   display: flex;
  //   flex-direction: column;
  // }
}
.mrg-create {
  margin-left: 24px;
  display: inline-block;
}
.mrg-right {
  margin-right: 8px;
}
.icon__s {
  margin-left: 14px;
  cursor: pointer;
}
</style>
