<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="7" class="border_right">
        <div class="flex_sp mgb-12">
          <h4>我的经销商分组</h4>
          <a-button
              type="primary"
              @click="
              () => {
                addDealerGroupVisible = true;
              }
            "
          >新建分组
          </a-button
          >
        </div>
        <common-table
            :headerData="headerData"
            :centerData="groupList"
            @changeList="getMyDealerGroup"
            :type="1"
            @getDealerList="getMyDealerList"
            ref="commTable"
            @getGroupDetail="getGroupDetail"
        >
        </common-table>
        <group-detail
            :detailData="detailData"
            :groupType="1"
            @handleChangeDealerGroup="handleChangeDealerGroup"
            @handleDeriveAuthorGroup="handleDeriveAuthorGroup"
            @updataList="searchList"
        />
      </a-col>
      <a-col :span="17">
        <div class="flex_sp mgb-12">
          <div style="display: flex;align-items: center">
            <span style="width: 150px">经销商名称/公司名称：</span
            >
            <a-input
                v-model="searchForm.dealerName"
                placeholder="请输入"
                :maxLength="100"
                style="width: 230px;margin-right: 10px"
            />
          </div>
        </div>
        <div class="flex_end">
          <a-button
              type="primary"
              @click="searchList"
              style="margin-right: 8px"
              :disabled="!groupList.length"
          >查询
          </a-button
          >
          <a-button @click="resetList" style="margin-right: 28px" :disabled="!groupList.length"
          >重置
          </a-button
          >
          <a-button
              type="primary"
              :loading="exportLoading"
              @click="handleExport"
              :disabled="!groupList.length || !tableListCn.length"
          >
            导出
          </a-button>
          <a-button
              type="primary"
              @click="onShowDrawer"
              :disabled="!groupList.length || !tableListCn.length"
              style="margin:0 15px "
          >
            根据标签筛选经销商
          </a-button>
          <a-button
              type="primary"
              @click="goToProblemStatisticsPage"
              style="margin-left: 10px"
          >
            问题统计
          </a-button>
        </div>
        <div style="padding-top: 20px ">
          <cn-table
              :configPayload="configDealer"
              :selectValue="searchForm"
              ref="cntable"
              :initData="false"
              :scrollSetting="{ x: 1, y: 600 }"
              @getTableList="getTableList"
          >
            <div slot="userType" slot-scope="text">
              {{ disposeUserType(text.userType) }}
            </div>
            <div slot="action" slot-scope="text">
              <router-link
                  :to="{
                  name: 'dealerDetail',
                  query: { dealer_id: text.dealerId,dealer_name: text.name },
                }"
                  target="_blank"
              >
                查看详情
              </router-link>
              <br>
              <template v-if="detailData.isCreator === 1 || detailData.isLeader === 1">
                <a-button type="link" @click="deleteDealer(text)"
                >从分组删除
                </a-button
                >
                <!-- <a-divider type="vertical"/> -->
                <br>
              </template>
              <a-button type="link" @click="openGroupModal(text)" :disabled="!text.authority"
              >添加标签
              </a-button
              >
            </div>
            <div slot="more" slot-scope="text">
              <div class="more_box">
                <p><span>编码：</span>{{ text.storeCode || "-" }}</p>
                <p><span>公司名称：</span>{{ text.companyName || "-" }}</p>
                <p><span>大区：</span>{{ text.regionName || "-" }}</p>
                <p><span>城市：</span>{{ text.cityName || "-" }}</p>
                <p><span>品牌：</span>{{ text.principalNameList || "-" }}</p>
              </div>
            </div>
          </cn-table>
        </div>
      </a-col>
    </a-row>
    <AddDealerGroupModal
        ref="addDealerGroupModal"
        v-model="addDealerGroupVisible"
        :form="addDealerGroupForm"
        :ok-loading="addDealerGroupLoading"
        @staff-select="handleAddDealerGroupStaffSelect"
        @ok="handleAddDealerGroupOk"
    />
    <BaseOrgModal
        v-model="baseOrgVisible"
        :multiple="baseOrgMultiple"
        :checked-data="baseOrgCheckedData"
        @confirm="handleBaseOrgConfirm"
    />
    <!-- 公转私 -->
    <ChangeDealerGroupModal
        ref="changeDealerGroupModal"
        v-model="changeDealerGroupVisible"
        :form="changeDealerGroupForm"
        :confirm-loading="changeDealerGroupLoading"
        @confirm="handleChangeDealerGroupConfirm"
    />
    <!-- 播主派生分组 -->
    <DeriveAuthorGroupModal
        ref="deriveAuthorGroupModal"
        v-model="deriveAuthorGroupVisible"
        :form="deriveAuthorGroupForm"
        :confirm-loading="deriveAuthorGroupLoading"
        @confirm="handleDeriveAuthorGroupConfirm"
    />
    <!-- 根据标签筛选经销商 -->
    <SelectDealerDrawer
        ref="SelectDealerDrawer"
        :drawerVisible="selectDrawerVisible"
        :detailData="detailData"
        @onCloseDrawer="onCloseDrawer"
        @tagSearchResult="tagSearchResult"
    />
    <!-- 添加标签 -->
    <DealerDrawer
        ref="DealerDrawer"
        :drawerVisible="drawerVisible"
        :title="'添加标签'"
        :dealer_id="dealer_id"
        :effectStartTime="effectStartTime"
        :effectEndTime="effectEndTime"
        @onCloseDrawer="onCloseDrawer"
        @onAddTag="onAddTag"
    />
  </div>
</template>
<script>
import _http from "@/api/personalcenter";
import columnArr from "./columns.js";
import CnTable from "@/components/CnTable";
import CommonTable from "@/components/MyGroup/CommonTable";
import GroupDetail from "@/components/MyGroup/GroupDetail";
import AddDealerGroupModal from "@/components/AddDealerGroupModal";
import dealerApi from "@/api/dealer";
import BaseOrgModal from "@/components/BaseOrgModal";
import ChangeDealerGroupModal from "@/components/ChangeDealerGroupModal";
import DeriveAuthorGroupModal from "@/components/DeriveAuthorGroupModal";
import SelectDealerDrawer from "@/components/DealerDrawer/SelectDealerDrawer";
import DealerDrawer from "@/components/DealerDrawer/DealerDrawer";
import Api from "@/api/tagManage";

export default {
  data() {
    return {
      searchForm: {
        dealerName: "",
        groupId: "",
        isCommon: "",
      },
      configDealer: {
        columns: columnArr.column_dealer,
        http_api: _http.getMyDealerList,
      },
      groupList: [],
      headerData: ["经销商分组", "类型", "操作"],
      addDealerGroupVisible: false,
      addDealerGroupForm: {
        groupType: 1,
        groupName: "",
        leaders: [],
        coordinators: [],
      },
      addDealerGroupLoading: false,
      baseOrgVisible: false,
      baseOrgMultiple: true,
      baseOrgCheckedData: [],
      exportLoading: false,
      detailData: {},
      changeDealerGroupVisible: false,
      changeDealerGroupForm: {
        sourceGroupId: "",
        sourceGroupName: "",
        dealerTotal: "",
        groupName: "",
      },
      changeDealerGroupLoading: false,
      deriveAuthorGroupVisible: false,
      deriveAuthorGroupLoading: false,
      deriveAuthorGroupForm: {
        sourceGroupId: "",
        sourceGroupName: "",
        dealerTotal: "",
        groupName: "",
        isSync: false,
        isCommon: 0,
      },
      selectDrawerVisible: false,
      drawerVisible: false,
      dealer_id: "",
      effectStartTime: this.$moment().startOf("week").format("YYYY-MM-DD"),
      effectEndTime: this.$moment().endOf("week").format("YYYY-MM-DD"),
      tableListCn: []
    };
  },
  components: {
    CnTable,
    CommonTable,
    AddDealerGroupModal,
    BaseOrgModal,
    GroupDetail,
    ChangeDealerGroupModal,
    DeriveAuthorGroupModal,
    SelectDealerDrawer,
    DealerDrawer,
  },
  created() {
    this.getMyDealerGroup(1);
  },
  methods: {
    // 获取经销商分组
    getMyDealerGroup(type) {
      _http.getMyDealerGroup().then((res) => {
        if(res.code == 200) {
          this.groupList = res.data;
          if(type) this.$refs.commTable.selectGroup(res.data[0]);
        } else {
          this.$message.error(res.message || res.errmsg);
        }
      });
    },
    getMyDealerList(value) {
      const {groupId, isCommon} = value;
      this.searchForm.groupId = groupId;
      this.searchForm.isCommon = isCommon;
      this.searchList();
    },
    resetList() {
      this.searchForm.dealerName = "";
      this.searchList();
    },
    // 搜索列表
    searchList() {
      this.$refs.cntable.getTableList(1, 10);
    },
    disposeRename() {
      // console.log(12);
    },
    handleAddDealerGroupOk() {
      this.addDealerGroup();
    },
    // 新建经销商分组
    async addDealerGroup() {
      this.addDealerGroupLoading = true;
      try {
        const {groupType, groupName, leaders, coordinators} =
            this.addDealerGroupForm;
        const params = {
          groupType,
          groupName,
        };

        if(groupType === 1) {
          params.leaderId = leaders[0].id;
          params.coordinatorIds = coordinators.map((item) => item.id);
        }

        const {code, errmsg} = await dealerApi.addDealerGroup(params);

        if(code === 0) {
          this.$message.success("新建经销商分组成功");
          this.addDealerGroupLoading = false;
          this.addDealerGroupVisible = false;
          this.$refs.addDealerGroupModal.resetForm();
          this.getMyDealerGroup();
        } else {
          this.$message.error(errmsg);
          this.addDealerGroupLoading = false;
        }
      } catch(e) {
        console.error(e);
        this.addDealerGroupLoading = false;
      }
    },
    handleAddDealerGroupStaffSelect(type) {
      this.staffSelectType = type;
      this.baseOrgVisible = true;
      if(this.staffSelectType === "leaders") {
        const {leaders} = this.addDealerGroupForm;

        this.baseOrgCheckedData = leaders.length > 0 ? [leaders[0].id] : [];
        this.baseOrgMultiple = false;
      }

      if(this.staffSelectType === "coordinators") {
        const {coordinators} = this.addDealerGroupForm;

        this.baseOrgCheckedData =
            coordinators.length > 0 ? coordinators.map((item) => item.id) : [];
        this.baseOrgMultiple = true;
      }
    },
    handleBaseOrgConfirm(val) {
      if(this.staffSelectType === "leaders") {
        this.addDealerGroupForm.leaders = val.checkedUser.map((item) => {
          return {id: item.key, name: item.title};
        });
      }

      if(this.staffSelectType === "coordinators") {
        this.addDealerGroupForm.coordinators = val.checkedUser.map((item) => {
          return {id: item.key, name: item.title};
        });
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true;
      try {
        await _http.getMyDealerExport(this.searchForm);
        this.exportLoading = false;
      } catch(e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    deleteDealer(value) {
      const {groupId, isCommon, dealerId} = value;
      let payload = {
        groupId,
        isCommon,
        dealerIds: [dealerId],
      };
      this.$confirm({
        title: "提示",
        content: "确要从分组中将该经销商删除吗？",
        onOk: () => {
          _http.deleteDealergroup(payload).then((res) => {
            if(res.code == 200) {
              this.searchList();
            } else {
              this.$message.error(res.errmsg);
            }
          });
        },
      });
    },
    // 获取分组详细信息
    getGroupDetail(id) {
      _http.getGroupDetail(id).then((res) => {
        // console.log("分组详细信息", res);
        if(res.code == 200) {
          this.detailData = res.data;
          console.log(this.detailData,'999999');
        }
      });
    },
    // 处理公转私确定
    async handleChangeDealerGroupConfirm() {
      this.changeDealerGroupLoading = true;
      try {
        const {sourceGroupId, groupName} = this.changeDealerGroupForm;
        const params = {
          sourceGroupId,
          groupName,
        };
        const {code, msg, errmsg} = await dealerApi.changeDealerGroup(params);

        this.changeDealerGroupLoading = false;

        if(code === 0) {
          this.$message.success("公转私成功");
          this.changeDealerGroupVisible = false;
          this.getMyDealerGroup();
        } else {
          this.$message.error(msg || errmsg);
        }
      } catch(e) {
        console.error(e);
        this.changeDealerGroupLoading = false;
      }
    },
    // 处理公转私
    handleChangeDealerGroup() {
      const {groupId, groupName, containDealer} = this.detailData;

      this.changeDealerGroupVisible = true;
      this.changeDealerGroupForm = {
        ...this.changeDealerGroupForm,
        sourceGroupId: groupId,
        sourceGroupName: groupName,
        dealerTotal: containDealer,
      };
    },
    // 处理派生播主分组
    handleDeriveAuthorGroup() {
      const {groupId, groupName, containDealer, isCommon, authorCount, authorDealerCount} = this.detailData;

      this.deriveAuthorGroupVisible = true;
      this.deriveAuthorGroupForm = {
        ...this.deriveAuthorGroupForm,
        sourceGroupId: groupId,
        sourceGroupName: groupName,
        dealerTotal: containDealer,
        groupName,
        isCommon,
        authorDealerCount,
        authorCount
      };
    },
    // 处理派生播主分组确定
    async handleDeriveAuthorGroupConfirm() {
      this.deriveAuthorGroupLoading = true;
      try {
        const {sourceGroupId, groupName, isSync, isCommon} =
            this.deriveAuthorGroupForm;
        const params = {
          sourceGroupId,
          groupName,
          isSync: isSync ? 1 : 0,
        };
        if(!isCommon) {
          delete params.sourceGroupId;
          params.groupId = sourceGroupId;
        }
        const {code, msg, errmsg} =
            isCommon == 1
                ? await dealerApi.deriveAuthorGroup(params)
                : await _http.changePrivately(params);

        this.deriveAuthorGroupLoading = false;

        if(code === 0 || code == 200) {
          this.$message.success("派生播主分组成功");
          this.deriveAuthorGroupVisible = false;
          this.getMyDealerGroup();
        } else {
          this.$message.error(msg || errmsg);
        }
      } catch(e) {
        console.error(e);
        this.deriveAuthorGroupLoading = false;
      }
    },
    openGroupModal(record) {
      this.dealer_id = record.dealerId;
      if(this.dealer_id) {
        this.$nextTick(() => {
          this.$refs.DealerDrawer.getLabelDisplay();
          this.drawerVisible = true;
        });
      }
    },
    onShowDrawer() {
      this.selectDrawerVisible = true;
      this.$refs.SelectDealerDrawer.getSiftDisplay();
    },
    onCloseDrawer(data) {
      this.selectDrawerVisible = data;
      this.drawerVisible = data;
    },
    tagSearchResult(data) {
      let routeUrl = this.$router.resolve({
        path: `/personalCenter/dealerTagList`,
        query: {
          tagsData: JSON.stringify(data),
          group_name: this.detailData.groupName || "-"
        }
      });

      window.open(routeUrl.href, "_blank");
    },
    onAddTag(data) {
      Api.saveDiagnoseLabel(data).then(res => {
        if(res.code == 200) {
          this.drawerVisible = false;
          this.$message.success("操作成功");
          this.getLabelDiagnose();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    getTableList(data) {
      this.tableListCn = data;
    },
    goToProblemStatisticsPage() {
      const {href} = this.$router.resolve({
        name: "problemStatistics",
        query: {
          detailData: JSON.stringify(this.detailData),
          isCommon: this.detailData.isCommon
        }
      });
      window.open(href, "_blank");
    }
  },
};
</script>
<style lang="scss" scoped>
.mgb-12 {
  margin-bottom: 12px;
}

.flex_b {
  display: flex;
}

.flex_sp {
  display: flex;
  justify-content: space-between;
}

.border_right {
  border-right: 1px solid #ccc;
}

.more_box {
  text-align: left;

  p {
    line-height: 1 !important;

    span {
      font-weight: bold;
    }
  }
}

.font__b {
  font-weight: bold;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
</style>
