<template>
  <a-modal
    :visible="value"
    title="派生播主分组"
    :width="680"
    :mask-closable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 140px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 140px)'}"
    >
      <a-form-model-item class="mb-0" label="对应经销商分组">
        {{ form.sourceGroupName || '-' }}（公共分组）
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="包含经销商">
        {{ form.dealerTotal | humanReadable }}
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="已绑定播主号经销商">
        {{ form.authorDealerCount | humanReadable }}
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="包含播主号">
        {{ form.authorCount | humanReadable }}
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="播主组类型">
        公共分组
      </a-form-model-item>
      <a-form-model-item label="分组名称" prop="groupName">
        <a-input
          v-model.trim="form.groupName"
          allow-clear
          placeholder="请输入分组名称"
          style="width: 340px;"
        />
        <span style="margin: 0 0 0 8px;">默认与经销商分组同名</span>
      </a-form-model-item>
      <a-form-model-item label="同步规则" prop="isSync">
        <div class="checkbox-wrap">
          <a-checkbox v-model="form.isSync" />
          <span>
            与经销商分组自动同步。当经销商分组更新时（如经销商增减、经销商媒体号增减等），其对应的播主号将在播主分组中自动更新
          </span>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <div class="btn-wrap">
        <div class="btn-wrap__left">
          <a>提示：你可以在派生播主分组后再调整分组內的播主帐号。同步规则依然生效。</a>
        </div>
        <div class="btn-wrap__right">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">
            确定
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'DeriveAuthorGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          sourceGroupId: '',
          sourceGroupName: '',
          dealerTotal: '',
          authorDealerCount: '',
          authorCount: '',
          groupName: '',
          isSync: false
        }
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        groupName: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.form.sourceGroupId = ''
      this.form.sourceGroupName = ''
      this.form.dealerTotal = ''
      this.form.authorDealerCount = ''
      this.form.authorCount = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}

.mb-0 {
  margin-bottom: 0;
}

.checkbox-wrap {
  display: flex;
  align-items: flex-start;
  position: relative;
  transform: translate(0, 25%);

  .ant-checkbox-wrapper {
    line-height: 20px;
  }

  span {
    display: inline-block;
    margin: 0 0 0 8px;
    line-height: 20px;
  }
}

.btn-wrap {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
    margin-right: 8px;
    text-align: left;
  }
}
</style>
