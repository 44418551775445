<template>
  <div>
    <a-drawer
        title="选择诊断标签"
        :width="1000"
        :visible="drawerVisible"
        :body-style="{ paddingBottom: '0' }"
        @close="onCloseDrawer"
    >
      <div class="search-box">
        <a-form-model class="panel panel-default panel-search" :model="form" layout="inline">
          <a-col flex="auto">
            <a-form-model-item label="标签名称">
              <a-input
                  v-model="labelName"
                  placeholder="请输入三级标签名称"
              />
            </a-form-model-item>
            <a-form-model-item label="生效周期" style="padding-top: 15px">
              <div class="picker-wrap">
                <a-radio-group button-style="solid" v-model="ctimeType" @change="handleCtimeTypeChange">
                  <a-radio-button
                      v-for="item in ctimeTypeList"
                      :key="item.value"
                      :value="item.value"
                  >
                    {{ item.label }}
                  </a-radio-button>
                </a-radio-group>
                <!--   <a-button-group>
                     <a-button
                         v-for="item in ctimeTypeList"
                         :key="item.value"
                         :type="item.value === ctimeType ? 'primary' : 'default'"
                         style="padding: 0 8px;"
                         @click="handleCtimeTypeChange(item.value)"
                     >
                       {{ item.label }}
                     </a-button>
                   </a-button-group>-->
                <div class="picker-box">
                  <div class="picker">
                    <a-icon type="calendar" class="calendar" v-if="pickerTime"/>
                    {{ pickerTime || "请选择时间" }}
                  </div>
                  <a-week-picker
                      v-model="weekTime"
                      format="YYYY-MM-DD"
                      placeholder="请选择时间"
                      @change="onChangeTime"
                      style="width: 100%;"
                      :allowClear="false"
                  />
                </div>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col class="tools">
            <a-space>
              <a-button @click="handleReset">重置</a-button>
              <a-button type="primary" @click="handleSearch">查询</a-button>
            </a-space>
          </a-col>
        </a-form-model>
      </div>
      <div class="content-box">
        <div class="tab-box">
          <a
              v-for="(item,index) of form.list"
              :key="index"
              :href="`#tab${index +1}`"
              :class="activeTab == index ?'activeTab': 'tab'"
              @click=getItem(item,index)
          >
            {{ item.labelName }}
          </a>
        </div>
        <div class="content" v-if="childrenList.length > 0">
          <div class="detail" id="tab1">
            <div class="wrap" v-for="(a,k) of childrenList" :key="k">
              <div
                  :class="labelName && a.labelName.indexOf(labelName) != -1 ?'title-color' : 'title'">
                {{ a.labelName }}:
              </div>
              <a-space :size="15" direction="horizontal" class="btn-box" v-if="a.items && a.items.length">

                <a-space
                    :size="6"
                    v-for="(tags, tagsIndex) of a.items"
                    :key="tags.labelItemCode + tagsIndex"
                    class="btn-box__tags"
                    @click="handleClickTags(tags, a)"
                    :class="{'btn-box__tags--multi': tags.children && tags.children.length > 1}"
                    style="margin-bottom: 10px"
                >
                  <a-tag
                      v-for="tag of tags.children"
                      :key="tag.id"
                      @click.stop="handleClickTag(tag, tags, a)"
                      :color="(checked[a.id] || []).some(check => check.id == tag.id) ? '#359fec' : ''"
                  >
                    {{ tag.labelName }}
                  </a-tag>
                </a-space>

              </a-space>
              <div v-else>
                <span style="display:inline-block;padding: 40px 0 0 70px">暂无标签</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content" v-else>
          <span style="display:inline-block;padding: 100px 0 0 70px;font-weight: bold;font-size: 20px">暂无标签</span>
        </div>
      </div>
      <div style="display: flex;align-items: center">
        <div class="select-box">
          <a-tag
              v-for="(tag, index) of checkedAll"
              :key="tag.id + '--' + index"
              @close.stop="handleRemoveTag(tag)"
              color="#359fec"
              closable
          >
            {{ tag.labelName }}
          </a-tag>
        </div>
        <div class="bottom-box">
          <a-button :style="{ marginRight: '8px' }" @click="onCloseDrawer">取消</a-button>
          <a-button type="primary" @click="onSubmit">确定筛选</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import Api from "@/api/tagManage";
import { some, remove, isEqual, cloneDeep, keys, flattenDeep } from "lodash-es";

export default {
  name: "dealerDrawer",
  props: {
    drawerVisible: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      form: {
        startTime: this.$moment().startOf("week").format("YYYY-MM-DD"),
        endTime: this.$moment().endOf("week").format("YYYY-MM-DD"),
      },
      pickerTime: `本周 | ${ this.$moment().startOf("week").format("YYYY-MM-DD") } 至 ${ this.$moment().endOf("week").format("YYYY-MM-DD") }`,
      weekTime: this.$moment(),
      ctimeType: "0", // 1, 2
      ctimeTypeList: [
        {label: "本周", value: "0"},
        {label: "上周", value: "-7"},
      ],
      childrenList: [],
      activeTab: 0,
      checked: {},
      labelName: ""
    };
  },
  mounted() {
  },
  methods: {
    getSiftDisplay() {
      let params = {
        labelName: this.labelName,
      };
      Api.getSiftDisplay(params).then((res) => {
        if(res.code == 200) {
          this.$set(this, "form", res.data || {});
          this.form.startTime = this.$moment().startOf("week").format("YYYY-MM-DD");
          this.form.endTime = this.$moment().endOf("week").format("YYYY-MM-DD");
          this.childrenList = this.form.list[this.activeTab].children || [];
        } else {
          this.$message.error("获取数据失败");
        }
      });
    },
    handleCtimeTypeChange() {
      let time = this.$moment();
      switch(this.ctimeType) {
        case "0":
          this.onChangeTime(time);
          break;
        case "-7":
          this.onChangeTime(this.$moment(time.startOf("week").subtract("week", 1).format("YYYY-MM-DD")));
          break;

      }
    },
    onChangeTime(date) {
      if(date) {
        let week = "0";
        let startTime = date.startOf("week").format("YYYY-MM-DD");

        if(startTime == this.$moment().startOf("week").format("YYYY-MM-DD")) {
          week = "0";
          this.ctimeType = "0";
        } else if(startTime == this.$moment().startOf("week").subtract("week", 1).format("YYYY-MM-DD")) {
          week = "-7";
          this.ctimeType = "-7";
        } else {
          week = date.week();
          this.ctimeType = "";
        }

        this.form.startTime = startTime;
        this.form.endTime = date.endOf("week").format("YYYY-MM-DD");
        this.pickerTime = `${ this.formatWeek(week) } | ${ startTime } 至 ${ this.form.endTime }`;
        this.weekTime = this.form.endTime;
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
        this.pickerTime = "";
        this.weekTime = "";
      }
    },
    formatWeek(week) {
      let txt = "";
      switch(week) {
        case "0":
          txt = "本周";
          break;
        case "-7":
          txt = "上周";
          break;
        default:
          txt = `第${ week }周`;
          break;
      }
      return txt;

    },
    onCloseDrawer() {
      this.labelName = "";
      this.ctimeType = "0";
      this.weekTime = this.$moment();
      this.pickerTime = `本周 | ${ this.$moment().startOf("week").format("YYYY-MM-DD") } 至 ${ this.$moment().endOf("week").format("YYYY-MM-DD") }`;
      this.form.startTime = this.$moment().startOf("week").format("YYYY-MM-DD");
      this.form.endTime = this.$moment().endOf("week").format("YYYY-MM-DD");
      this.$set(this, "checked", {});
      this.$emit("onCloseDrawer", false);
    },
    onSubmit() {
      if(this.checkedIds && this.checkedIds.length > 0) {
        const tagsData = {
          labelIds: this.checkedIds,
          effectStartTime: this.form.startTime + " 00:00:00",
          effectEndTime: this.form.endTime + " 23:59:59",
          groupId: this.detailData.groupId,
          isCommon: this.detailData.isCommon,
        };

        this.$emit("tagSearchResult", tagsData);
      } else {
        this.$message.error("请先选择标签");
      }

    },
    handleReset() {
      this.labelName = "";
      this.ctimeType = "0";
      this.weekTime = this.$moment();
      this.pickerTime = `本周 | ${ this.$moment().startOf("week").format("YYYY-MM-DD") } 至 ${ this.$moment().endOf("week").format("YYYY-MM-DD") }`;
      this.form.startTime = this.$moment().startOf("week").format("YYYY-MM-DD");
      this.form.endTime = this.$moment().endOf("week").format("YYYY-MM-DD");
      this.getSiftDisplay();
    },
    handleSearch() {
      this.getSiftDisplay();
    },
    getItem(item, index) {
      this.childrenList = [];
      this.childrenList = item.children || [];
      this.activeTab = index;
    },
    // 处理点击标签盒子
    handleClickTags(tabs, all) {
      /*let checked = [];
      if(tabs?.children.every(v => cloneDeep(this.checked[all.id])?.some(i => v.id == i.id))) {
        checked = [];
      } else {
        checked = tabs.children;
      }*/
      let checked = cloneDeep(this.checked[all.id] || []);

      // 处理所有都存在
      if(tabs?.children.every(v => cloneDeep(this.checked[all.id])?.some(i => v.id == i.id))) {
        checked = remove(checked, (n) => !tabs.children.some(v => isEqual(v.id, n.id)));
      } else {
        const fl = tabs?.children?.filter(v => !checked?.some(s => isEqual(v.id, s.id))) || [];
        checked = [...checked, ...fl];
      }

      this.$set(this.checked, all.id, checked);
    },
    // 处理点击标签
    handleClickTag(tag, tabs, all) {
      let checked = cloneDeep(this.checked[all.id] || []);
      // if(!tabs?.children.some(v => checked?.some(i => i.id == v.id))) {
      //   checked = [];
      // }

      if(some(this.checked[all.id], ["id", tag.id])) {
        checked = remove(checked, (n) => !isEqual(n.id, tag.id));
      } else {
        checked.push(tag);
      }
      this.$set(this.checked, all.id, checked);
    },
    // 处理删除
    handleRemoveTag(tag) {
      let checked = cloneDeep(this.checked[tag.__parent_id__]);
      checked = remove(checked, (n) => !isEqual(n.id, tag.id));
      this.$set(this.checked, tag.__parent_id__, checked);
    },

  },
  computed: {
    // 获取所有选中项
    checkedAll() {
      const checked = cloneDeep(this.checked);
      return flattenDeep(keys(checked).map(id => checked[id].map(item => ({...item, __parent_id__: id}))));
    },
    // 获取所有选中项ID
    checkedIds() {
      return this.checkedAll.map(v => v.id);
    }
  }
};
</script>

<style scoped lang="less">
.search-box {
  margin-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;

  .picker-wrap {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .picker-box {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
    width: 350px;

    .picker {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 12px;
      width: 100%;
      height: 32px;
      line-height: 32px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }

    .calendar {
      color: #999;
    }

    /deep/ .ant-calendar-picker-input {
      opacity: 0;
    }
  }
}

.content-box {
  display: flex;
  height: calc(100vh - 55px - 40px - 246px - 53px);

  .tab-box {
    height: 100%;
    width: 150px;
    text-align: center;
    overflow: auto;

    .tab {
      display: block;
      font-size: 15px;
      font-weight: bold;
      width: 100%;
      height: 40px;
      background: #fff;
      margin: 10px 0;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.65);
    }

    .activeTab {
      display: block;
      font-size: 15px;
      font-weight: bold;
      width: 100%;
      height: 40px;
      background: #eaf5ff;
      margin: 10px 0;
      line-height: 40px;
      color: #40a9ff;
    }
  }

  .content {
    flex: 1;
    padding: 0 10px 0 20px;
    margin-left: 20px;
    height: 100%;
    overflow: auto;
    border-left: 1px solid #e9e9e9;

    .wrap {
      margin-bottom: 60px;
    }

    .title {
      font-size: 15px;
      font-weight: bold;
    }

    .btn-box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      &__tags {
        display: flex;
        flex-wrap: wrap;

        .ant-tag {
          padding: 4px 10px;
          cursor: pointer;
          margin: 4px;

          &:last-child {
            margin-right: 0;
          }
        }

        &--multi {
          border: 1px solid #ccc;
          padding: 4px 8px;
          cursor: pointer;
          border-radius: 4px;
        }
      }

      .box {
        display: inline-block;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 8px;
        margin: 8px;
      }

      .span-tab {
        padding: 4px 8px;
        border: 1px solid #ccc;
        margin: 6px;
        cursor: pointer;
      }

      .span-tab-active {
        padding: 4px 8px;
        border: 1px solid #40a9ff;
        margin: 6px;
        cursor: pointer;
        color: #40a9ff;
        background: #eaf5ff;
      }
    }
  }
}

.select-box {
  width: 88%;
  background: #fff;
  padding: 10px 16px;
  border-top: 1px solid #e9e9e9;
  height: 200px;
  overflow-y: auto;

  .ant-tag {
    padding: 4px 10px;
    cursor: pointer;
    margin: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.bottom-box {
  padding: 10px 16px;
  display: flex;
  text-align: right;
}

.title-color {
  font-size: 15px;
  font-weight: bold;
  color: #40a9ff;
}
</style>
